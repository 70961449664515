/*
	* Gridless version 2.0

	* Credit is left inline and big thanks to Nicolas Gallagher and Jonathan Neal

	* Across this document we use safe CSS hacks: http://mathiasbynens.be/notes/safe-css-hacks
	* Selectors beggining with an underscore (_selector: property) target only IE6
	* Selectors beggining with an asterisk (*selector: property) target only IE6 and IE7
*/

/* HTML5 display definitions
---------------------------------------- */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, dialog {
  display: block;
}

/* Corrects inline-block not defined in IE6/7/8 and FF3 */
audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

/* Ensures content is hidden from all presentations, including screenreaders */
[hidden] {
  display: none;
  visibility: hidden;
}

/* Base structure
---------------------------------------- */

/*
	* The body will work like a 'div#wrapper' (for this to work, the 'body' needs to have a set width)

	* To add a background to the PAGE, set it in the 'html' element
	* To add a background to the WRAPPER, set it in the 'body' element
*/
html {
  height: 100%;
  /*font-size: 62.5%;*/
  overflow-y: scroll; /* Force a scrollbar in non-IE */
  -webkit-text-size-adjust: 100%; /* Prevent iOS text size adjust on orientation change without disabling user zoom */
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0 auto;
  min-height: 100%;
}

/* Fonts settings based on the 100E2R standard: http://www.informationarchitects.jp/en/100e2r/ */
body, button, input, select, textarea {
  /*font: 1em/1.625 'museo_sans_rounded500', serif;
  color: #222; /* Full black on white is too much contrast, #222 is a better default */
}

/* Add and/or remove tags as your baseline grid needs */
p, blockquote, q, pre, address, hr, code, samp, dl, ol, ul, form, table, fieldset, menu, h4, h5, h6, img, figure, figcaption, button, hr {
  margin: 0;
}

/* Headings/small
---------------------------------------- */

/*
	* Font sizes are based on the golden ratio of 16
	* See this for the modular scale: ow.ly/5jGl6
	* Line-heights and margins are adjusted to keep a 26px (1.5rem) vertical rhythm across elements
*/
/*
h1, h2, h3, h4, h5, h6 {
	font-family:'museo_sans_rounded900', sans-serif;
	font-size: 1em;
	font-weight: normal;
}
h1 {
	font-size: 4.25em;
	line-height: 1.1471em;
	margin: 0 0 0.3824em;
}

h2 {
	font-size: 2.625em;
	line-height: 1.2381em;
	margin: 0 0 0.619em;
}

h3 {
	font-size: 1.5rem;
	line-height: 1em;
	margin: 0 0 1em;
}

small {
	font-size: 0.625em;
	margin: 0 0 2.6em;
}
*/
/* Preformatted text and code
---------------------------------------- */

/* Allows line wrapping of 'pre' */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

pre, code, kbd, samp {
  font: 1em/1.5rem Menlo, Consolas, 'DejaVu Sans Mono', Monaco, 'Courier New', Courier, monospace;
}

/* Tables
---------------------------------------- */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  text-align: left;
}

tr, th, td {
  /*padding-right: 1.5rem;*/
}

/* Forms
---------------------------------------- */

form {
  margin: 0;
}

fieldset {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

legend {
  border: 0;
  *margin-left: -7px;
}

button, input, select, textarea {
  vertical-align: baseline;
  *vertical-align: middle;
}

button, input {
  line-height: normal;
  *overflow: visible;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Reintroduce inner spacing in 'table' to avoid overlap and whitespace issues in IE6/7 */
table button, table input {
  *overflow: auto;
}

/* Quotes
---------------------------------------- */

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

blockquote, q, cite {
  font-style: italic;
}

blockquote {
  padding-left: 1.5rem;
  border-left: 1px solid #ddd;
}

blockquote > p {
  padding: 0;
}

/* Lists
---------------------------------------- */

ul, ol {
  list-style-position: inside;
  padding: 0;
}

li ul, li ol {
  margin: 0 1.5rem;
}

dl dd {
  margin-left: 1.5rem;
}

/* Links
---------------------------------------- */

a, a:visited {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

a:focus {
  outline: thin dotted;
}

/* Better CSS outline suppression: people.opera.com/patrickl/experiments/keyboard/test */
a:hover, a:active {
  outline: none;
}

/* Figures
---------------------------------------- */

figure {
  margin: 0;
}

/* Embedded content
---------------------------------------- */

img, object, video {
  max-width: 100%; /* Automatically scales images larger than the container. Consider this first: http://unstoppablerobotninja.com/entry/fluid-images/ */
  /* _width: 100%; /* IE6 doesn't support max-width, so we just use width. If the image is larger than the container, just uncomment this. If it is smaller than the container, uncomment and change the 100% value to an absolute one */
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic; /* Improve IE's resizing of images: css-tricks.com/ie-fix-bicubic-scaling-for-images */
}

/* Corrects overflow displayed oddly in IE9 */
svg:not(:root) {
  overflow: hidden;
}

/* Abbreviations
---------------------------------------- */

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

/* Marked/inserted/deleted text
---------------------------------------- */

mark {
  background: #ff0;
}

ins {
  text-decoration: none;
  background: #ff9;
}

del {
  text-decoration: line-through;
}

/* Others
---------------------------------------- */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-bottom: 1px solid #ddd;
}

strong, b, dt {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

var, address {
  font-style: normal;
}

/* Position 'sub' and 'sup' without affecting line-height: gist.github.com/413930 */
sub, sup {
  font-size: 0.625em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Helper classes
---------------------------------------- */

/*
	* Micro clearfix hack, more semantically titled with 'group'
	* Source: nicolasgallagher.com/micro-clearfix-hack/
*/
.group:before, .group:after {
  content: "";
  display: table;
}

.group:after {
  clear: both;
}

.group {
  *zoom: 1;
}

.justify-fix {
  font-size: 0;
  height: 0;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  margin: 0;
}

img {
  vertical-align: top;
}

/* Print styles
---------------------------------------- */

/* Print styles inlined to avoid extra HTTP connection */

@media print {
  * {
    background: transparent !important;
    color: black !important; /* Black prints faster: sanbeiji.com/archives/953 */
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /* Do not show javascript and internal links */
  a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }

  /* Printing Tables: css-discuss.incutio.com/wiki/Printing_Tables */
  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  @page {
    margin: 0.5cm;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}