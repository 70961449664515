// Legacy
@import 'legacy/fonts';
@import 'legacy/reset';
@import 'legacy/public';
@import 'legacy/animate';

@media (max-width: 905px) {
  .login-wrapper .poza {
    display: none;
  }

  .login-wrapper .login-form {
    margin: auto;
    padding-top: 0;
    padding-bottom: 12vw;
  }

  .login-wrapper .form {
    width: 80%;
  }
}

.wow {
  visibility: hidden
}