// COLORS
$primary-color: #44a3dc;
$black: #08161e;
$gray: #777;
$dark-gray: #636d7c;
$light-gray: #efedee;
$bg-gray: #f6f6f6;
$white: #fff;
$true-black: #000;
$red: #ef1803;
$yellow: #f9d302;
$green: #0cc42e;

//OTHER VARIABLES
$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Roboto Condensed', sans-serif;
$primary-font-size: 16px;
$light: 300;
$regular: 400;
$bold: 700;

//MIXINS
@mixin box-sizing($box-sizing-value) {
  -webkit-box-sizing: $box-sizing-value;
  -moz-box-sizing: $box-sizing-value;
  box-sizing: $box-sizing-value;
}

*,
*:before,
*:after {
  @include box-sizing(border-box);
  //transition: all .2s ease;
  -webkit-font-smoothing: antialiased;
}

html {
  height: 100%;
  overflow: hidden;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-family: $font-primary;
  min-height: 100%;
  height: 100%;
  font-size: $primary-font-size;
  line-height: 1.5;
  color: $black;
  background-color: $black;
  font-weight: $regular;
  -webkit-overflow-scrolling: touch;
}

strong {
  font-weight: $bold;
}

/*
.mega-wrapper {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	width: 280vw;
	position: fixed;
	left: calc(-100vw + 50px);
	transition: left .5s ease;
	&.mm-open {
		left: 0;
	}
}

.mobile-menu {
	height: 100vh;
	width: calc(100vw - 50px);
	background-color: rgba($green,.25);
	padding: 20px;
	color: $white;

	left: calc(-100vw + 50px);
	right: -50px;
	position: fixed;
	top: 0;
	transition: left .5s ease, right .5s ease;
	&.open {
		left: 0;
		right: -50px;
	}
}
*/

.mobile-menu {
  height: 100vh;
  width: 270px;
  background-color: darken($primary-color, 35%);
  padding: 20px;
  color: $white;
  z-index: 99999;
  left: -270px;
  position: fixed;
  top: 0;
  transition: left 0.5s ease, right 0.5s ease;
  &::after {
    position: absolute;
    width: 0;
    content: '';
    height: 100vh;
    background-color: rgba($black, 0.9);
    top: 0;
    left: 269px;
    transition: opacity 0.5s ease;
    opacity: 0;
  }
  &.open {
    left: 0;
    &::after {
      width: 100vw;
      opacity: 1;
    }
  }
  &.closed {
    left: -270px;
  }
  .logo {
    height: 60px;
    width: 60px;
    display: block;
    margin-bottom: 20px;
  }
  .menu {
    width: auto;
    margin: 0;
    background: none;
    ul {
      margin-bottom: 20px;
      li {
        a {
          justify-content: flex-start;
          padding: 0 15px;
          width: 100%;
          span {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.close-button {
  position: absolute;
  right: 15px;
  top: 13px;
  display: block;
  span {
    font-size: 24px;
  }
}

.main-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url(/assets/img/bg_form-300774.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
}

.landscape-warning {
  display: none;
}

.header {
  background-color: rgba($black, 0.3);
  box-shadow: 0 1px 0 rgba($white, 0.3);
  height: 51px;
  width: 100%;
  margin-bottom: 23px;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 22px;
  .toggle-menu {
    width: 50px;
    height: 51px;
    background-color: rgba($black, 0.5);
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 24px;
      display: block;
    }
  }
  .logo {
    display: block;
    width: 33px;
    margin: 0 10px 0 22px;
  }
  .breadcrumb {
    font-family: $font-secondary;
    font-size: 28px;
    font-weight: $light;
    text-shadow: 0 1px 0 rgba($black, 0.3);
    flex: 1;
  }
  .status {
    border-right: 1px solid rgba($white, 0.25);
    padding: 0 15px;
    display: block;
    span {
      display: block;
      line-height: 1.1;
      font-family: $font-secondary;
      &.primary {
        font-size: 24px;
      }
      &.secondary {
        text-transform: uppercase;
        font-size: 12px;
      }
      &.tertiary {
        font-size: 12px;
        padding: 3px 0 2px;
        width: 28px;
        text-align: center;
        background-color: $gray;
        margin: 1px 0;
        border-radius: 3px;
        &.gray {
          background-color: $gray;
        }
        &.red {
          background-color: $red;
        }
        &.yellow {
          background-color: $yellow;
          color: $black;
        }
        &.green {
          background-color: $green;
        }
      }
    }
    &.highlighted {
      .primary {
        color: $red;
      }
    }
  }
  .user {
    display: block;
    padding: 0 0 0 15px;
    .profile-pic {
      height: 42px;
      width: 42px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
    }
  }
}

.wrapper {
  width: 100%;
  flex: 1;
  padding-right: 22px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.menu {
  margin-right: 22px;
  width: 50px;
  background: rgba($black, 0.7);
  ul {
    list-style-type: none;
    li {
      display: block;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        border-bottom: 1px solid rgba($white, 0.1);
        box-shadow: inset 1px 0 0 rgba($white, 0.25);
        position: relative;
        span {
          display: block;
          font-size: 24px;
          color: rgba($white, 0.5);
        }
        &:hover {
          span {
            color: $white;
          }
        }
        &.selected {
          background-color: $primary-color;
          span {
            color: $white;
          }
        }
      }
    }
  }
}

.content-wrapper {
  flex: 1;
  display: flex;
}

.menu-setari {
  background-color: rgba($black, 0.5);
  width: 210px;
  border-radius: 3px 0 0 3px;
  height: auto;
  overflow-y: scroll;
  color: rgba($white, 0.8);
  text-shadow: 0 1px 0 rgba($black, 0.25);
  .photo-user {
    text-align: center;
    box-shadow: inset 0 -1px 0 rgba($black, 0.2), 0 1px 0 rgba($white, 0.2);
    padding-bottom: 30px;
    img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      margin-top: 35px;
      box-shadow: 0 0 0 8px rgba($white, 0.1);
    }
  }
  .sectiune-meniu-setari {
    padding: 18px 0;
    box-shadow: inset 0 -1px 0 rgba($black, 0.2), 0 1px 0 rgba($white, 0.2);
    &:last-child {
      box-shadow: none;
    }
    h6 {
      font-family: $font-secondary;
      font-weight: $bold;
      text-transform: uppercase;
      font-size: 16px;
      color: $primary-color;
      margin: 0 0 5px;
      padding: 0 20px;
    }
    ul {
      list-style-type: none;
      li {
        a {
          display: block;
          padding: 3px 20px 2px;
          font-family: $font-secondary;
          line-height: 1.2;
          text-transform: uppercase;
          &:hover {
            background-color: $white;
            color: $black;
            text-shadow: none;
          }
          &.selected {
            background-color: $primary-color;
            color: $white;
            text-shadow: none;
          }
        }
      }
    }
    select {
      margin: 0 20px;
      width: calc(100% - 40px);
      background-color: $white;
    }
  }
}

.filtre {
  //background-color: $light-gray;
  background-color: rgba($black, 0.5);
  width: 210px;
  border-radius: 3px 0 0 3px;
  height: 100%;
  color: $black;
  display: flex;
  flex-direction: column;
  h3 {
    margin: 0;
    color: $primary-color;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: $regular;
    font-family: $font-secondary;
    padding: 7px 20px 6px;
    box-shadow: inset 0 -1px 0 rgba($black, 0.2), 0 1px 0 rgba($white, 0.2);
  }
  .scroller {
    flex: 1;
    overflow-y: scroll;
    padding: 18px 20px;
    background-color: rgba($black, 0.1);
    box-shadow: inset 0 -1px 0 rgba($black, 0.2), 0 1px 0 rgba($white, 0.2);
    label {
      color: rgba($white, 0.8);
    }
    input {
      width: 100%;
      background-color: rgba($black, 0.35);
      border: 1px solid rgba($white, 0.25);
      color: $white;
      &:focus {
        border: 1px solid $red;
      }
    }
  }
  .buttons {
    padding: 15px 20px;
    .secondary {
      float: right;
      width: 40px;
      margin-top: 2px;
      width: 60px;
    }
  }
  &.mobile {
    display: none;
  }
  &.info {
    width: 30%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    background-color: $bg-gray;
    h3 {
      margin: 20px 0 18px;
      color: $gray;
      padding: 7px 0 6px;
    }
    p {
      font-size: 13px;
      margin-bottom: 13px;
    }
    input {
      background-color: $white;
      width: 100%;
    }
  }
}

.latime-maxima {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 40px;
}

.floating-buttons {
  position: absolute;
  bottom: 10px;
  right: 60px;
  z-index: 99;
}

.buttons-bar {
  margin-top: 20px;
  width: 100%;
}

#up {
  display: none;
}

#sorteaza {
  #icon-cross {
    display: inline-block;
  }
  #icon-equalizer {
    display: none;
  }
  &.closed {
    #icon-cross {
      display: none;
    }
    #icon-equalizer {
      display: inline-block;
    }
    .sort-submenu {
      display: none;
    }
  }
}

.users-menu-mobile {
  display: none;
}

.l-i-wrapper {
  display: flex;
  margin-bottom: 15px;
  label {
    font-size: 12px;
    text-transform: uppercase;
    width: 125px;
    display: block;
    margin: 0;
    padding: 8px 0 0;
    color: $gray;
  }
  input,
  select,
  textarea {
    flex: 1;
  }
  input[type='submit'] {
    flex: 0;
  }
}

input,
select,
textarea {
  @include box-sizing(border-box);
  font-family: $font-primary;
  background-color: $bg-gray;
  border-radius: 3px;
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 8px 10px 7px;
  align-items: center;
  font-size: 13px;
  border: 1px solid rgba($black, 0.2);
  margin-bottom: 15px;
  @include box-sizing(border-box);
  &:focus {
    border: 1px solid $primary-color;
  }
  &.error {
    background-color: rgba($red, 0.2);
    border: 1px solid $red;
    color: $red;
  }
}

textarea {
  min-height: 100px;
  resize: none;
}

input[type='checkbox'] {
  width: 14px;
  height: 14px;
  padding: 0;
  border: 1px solid $gray;
  margin: 0;
  -webkit-appearance: checkbox;
}

select {
  background-image: url(/assets/img/dropdown.svg);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}

input[type='submit'] {
  display: inline-block;
  background-color: $primary-color;
  text-transform: uppercase;
  font-family: $font-secondary;
  padding: 8px 15px 7px;
  margin-top: 5px;
  border: 1px solid $primary-color;
  &:hover,
  &:focus {
    background-color: rgba($primary-color, 0.9);
  }
}

label {
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  display: block;
  margin: 0 0 2px;
  color: $gray;
  &.error {
    color: $red;
  }
}

button {
  -webkit-appearance: none;
  background-color: $gray;
  color: $white;
  font-family: $font-secondary;
  text-transform: uppercase;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  padding: 7px 20px 6px;
  margin-right: 5px;
  &:hover,
  &:focus {
    outline: none;
    background-color: darken($gray, 6%);
  }
  &.secondary {
    background: none;
    color: $gray;
    text-align: left;
    padding: 0;
    font-size: 12px;
    margin-top: 15px;
    width: 100%;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  &.small {
    font-size: 12px;
    padding: 8px 15px;
  }
  &.colored {
    background-color: $primary-color;
    &:hover,
    &:focus {
      background-color: darken($primary-color, 5%);
    }
  }
  &.red {
    background-color: $red;
    &:hover,
    &:focus {
      background-color: darken($red, 5%);
    }
  }
  &.green {
    background-color: $green;
    &:hover,
    &:focus {
      background-color: darken($green, 5%);
    }
  }
}

.login-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh;
  .poza {
    width: 50%;
    background-image: url(/assets/img/bg_body-300774.jpg);
    background-size: cover;
    background-position: center;
    color: $white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .transparent {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($true-black, 0.65);
    }
    .hero-text {
      position: relative;
      width: 50%;

      img {
        margin-bottom: 20px;
        margin-top: 7px;
      }

      h1 {
        font-family: $font-primary, sans-serif;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 10px;
      }

      p:last-of-type {
        color: #376DB5;
        font-weight: bold;
        margin-top: 20px;
        letter-spacing: 1px;
      }
    }
  }
  .login-form {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .form {
    width: 60%;
    max-width: 400px;
    color: $white;
  }
  img {
    margin-bottom: 40px;
  }
  h1 {
    margin: 0 0 20px;
    font-family: $font-secondary;
    font-size: 40px;
    font-weight: $light;
  }
  label {
    color: $white;
  }
  input[type='checkbox'] {
    width: auto;
    margin: 0 8px 0 0;
  }
  input[type='checkbox'] + label {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    font-size: 14px;
    text-transform: none;
  }
  input {
    width: 100%;
  }
  button {
    margin-top: -5px !important;
  }
  .forgot {
    display: block;
    float: right;
    font-size: 14px;
    border-bottom: 1px dotted rgba($white, 0.5);
    &:hover,
    &:focus {
      border-bottom: 1px solid rgba($white, 0.5);
    }
  }
  .spacer {
    height: 10px;
  }
  .eroare-login {
    color: $yellow;
    display: none;
    margin: -15px 0 25px;
  }
}

.tooltip {
  background-color: rgba($black, 0.9);
  color: $white;
  font-size: 13px;
  display: inline-block;
  margin: 0 0 20px;
  padding: 3px 10px;
  border-radius: 3px;
  position: relative;
  &::after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid $black;
    position: absolute;
    top: -7px;
    left: 11px;
  }
}

.mesaje-eroare {
  background-color: rgba($red, 0.1);
  width: 100%;
  padding: 20px 30px;
  margin-bottom: 20px;
  border-radius: 3px;
  font-size: 13px;
}

.footer {
  height: 25px;
  width: 100%;
}

.mobile-only,
.header .mobile-only,
.content-setari .header-content ul .mobile-only {
  display: none;
}

//BREAKPOINTS
$small-desktop-devices: 'all and (max-width: 1020px)';
$tablet-devices: 'all and (max-width: 920px)';
$mobile-devices: 'all and (max-width: 700px)';
$landscape-mobile-devices: 'all and (max-height: 500px) and (orientation: landscape)';

//MEDIA QUERIES

@media #{$small-desktop-devices} {
  .menu-setari {
    display: none;
  }
}

@media #{$tablet-devices} {
  .mobile-only {
    display: block;
  }
  .header .mobile-only {
    display: flex;
  }
  .content-setari .header-content ul .mobile-only {
    display: inline-block;
  }
  .header {
    padding-right: 0;
    margin-bottom: 0;
    .breadcrumb {
      display: none;
    }
    .status {
      display: none;
    }
    .user {
      display: none;
    }
    .toggle-menu {
      background: none;
    }
  }
  .wrapper {
    padding-right: 0;
    .menu {
      display: none;
    }
  }
  .filtre {
    display: none;
    &.mobile {
      border-radius: 0;
      width: 320px;
      height: calc(100vh - 75px);
      display: flex;
      position: fixed;
      z-index: 99999;
      top: 0;
      right: -320px;
      transition: right 0.5s ease;
      &::after {
        position: absolute;
        width: 10px;
        content: '';
        height: 100vh;
        background-color: rgba($black, 0.9);
        top: 0;
        right: -10px;
        left: auto;
        transition: opacity 0.5s ease;
        opacity: 0;
        z-index: -2;
      }
      &.open {
        right: 0;
        &::after {
          width: 100vw;
          left: 0;
          right: 0;
          opacity: 1;
          position: fixed;
          z-index: -2;
        }
      }
      &.closed {
        right: -320px;
      }
      .mobile-bg {
        position: absolute;
        background-color: $bg-gray;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
      .scroller {
        box-shadow: none;
        label {
          color: $gray;
        }
        input {
          background-color: $white;
          border: 1px solid rgba($black, 0.25);
          color: $black;
        }
      }
      h3 {
        padding: 13px 20px 11px;
        box-shadow: none;
      }
    }
  }
  .hide-on-mobile {
    display: none !important;
  }
  .content-setari {
    border-radius: 0;
    .header-content {
      padding: 0 10px 0 15px;
      ul {
        font-size: 12px;
        li {
          & > a {
            padding: 5px 2.1875vw;
            font-size: 0;
          }
        }
      }
    }
  }
  footer {
    display: none;
  }
  .editare {
    overflow-x: hidden;
    overflow-y: scroll;
    .content-setari {
      flex-direction: row;
      flex-wrap: wrap;
      overflow-y: visible;
      overflow-x: visible;
      .info {
        display: flex;
        width: 100%;
        height: auto;
        h3 {
          margin: 10px 0 13px;
        }
      }
      .body-content {
        overflow-x: visible;
        overflow-y: visible;
        .wrapper-editare {
          .adauga-fisiere {
            padding: 80px 20px;
          }
          p {
            &.right {
              text-align: left;
            }
          }
        }
      }
    }
  }
  .content-setari {
    .body-content {
      .wrapper-editare {
        position: static;
        padding: 25px 20px;
        .column {
          width: 100%;
        }
        .latime-maxima {
          max-width: 500px;
          flex-wrap: wrap;
        }
      }
    }
  }
  .latime-maxima {
    padding-bottom: 5px;
  }
  .floating-buttons {
    right: 10vw;
    position: fixed;
    button {
      position: relative;
      z-index: 99;
    }
    &::before {
      content: '';
      display: block;
      position: fixed;
      z-index: 98;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 25px;
      background-color: rgba($bg-gray, 0.98);
    }
  }
  .sort-submenu {
    top: 32px;
    &#views {
      top: 31px;
    }
  }
  .users-title-desktop {
    display: none;
  }
  .users-menu-mobile {
    display: block;
  }
}

@media #{$mobile-devices} {
  .t-info {
    display: none;
  }
  .t-numbers {
    display: none;
  }
  .floating-buttons {
    right: 15px;
  }
  .login-wrapper {
    flex-direction: column;
    .poza {
      display: none;
    }
    .login-form {
      height: 480px;
      width: 100%;
      align-items: flex-start;
      padding-top: 12vw;
      .form {
        width: 80%;
        margin: auto;
      }
      img {
        margin-bottom: 20px;
      }
      h1 {
        font-size: 6vh;
        margin-bottom: 12px;
      }
      button {
        margin-top: 10px;
      }
    }
  }
  .content-setari {
    .body-content {
      .admin-table {
        .t-tel {
          display: none;
        }
        .t-date {
          display: none;
        }
        .t-actions {
          display: none;
        }
      }
    }
  }
}

@media #{$landscape-mobile-devices} {
  .landscape-warning {
    display: block;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($black, 0.97);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999999;
    color: $white;
    div {
      max-width: 60%;
      text-align: center;
      span {
        display: block;
        font-size: 64px;
        margin-bottom: 20px;
      }
    }
  }
}
